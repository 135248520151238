import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Title from 'components/title';
import Gallery from 'components/gallery';
import { graphql } from 'gatsby';
import Footer from 'components/footer/Footer';
import './index.css';
import { Copy } from '../components/gallery/item/item.css';

//{data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
const Index = ({ data }) => (
  <Layout>
    <div className="layout-div"
      style={{
        background: 'url({data.allFile.edges[0].node.childHomeJson.imageBackground.publicURL})',
      }}
    >
      <figure>
        <a href={data.allFile.edges[0].node.childHomeJson.imageBackground.publicURL}>
          <img
            src={data.allFile.edges[0].node.childHomeJson.imageBackground.publicURL}
            alt={'home background'}
            width={'100%'}
          />
        </a>
        <figcaption className={'background-figcaption'}>
          <Title as="h2" size="large">
            <div
              className="source"
              dangerouslySetInnerHTML={{
                __html: data.allFile.edges[0].node.childHomeJson.content.childMarkdownRemark.html,
              }}
            />
          </Title>
          <Copy className="source-light">{data.allFile.edges[0].node.childHomeJson.annotationTitle}</Copy>
        </figcaption>
      </figure>
    </div>
    <Gallery items={data.allFile.edges[0].node.childHomeJson.gallery}/>
    <Footer footers={data.allFile.edges[0].node.childHomeJson.footers} />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
query {
  allFile (filter: {
    name: { eq : "home.en" }
  }) {
    edges {
      node {
        dir
        name
        extension
        relativeDirectory
        childHomeJson {
          title
          annotationTitle
          content {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          imageBackground {
            publicURL
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          gallery {
            title
            copy
            url
            image {
              childImageSharp {
                fluid(maxHeight: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          footers {
          	title
            description {
              label
              link
            }
          }
        }
      }
    }
  }
}
`;
